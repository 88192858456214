import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-sans\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["ErrorBoundary"] */ "/vercel/path0/node_modules/react-error-boundary/dist/react-error-boundary.esm.js");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/node_modules/sonner/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["GlobalError"] */ "/vercel/path0/src/components/global-error.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/providers/jotai-store.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["QueryProvider"] */ "/vercel/path0/src/providers/query.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/vercel/path0/src/providers/theme.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WalletContextProvider"] */ "/vercel/path0/src/providers/wallet.tsx");
